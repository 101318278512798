import React from 'react';
import { File } from '@model/generated/index';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, Link, PageProps } from 'gatsby';
import { Layout } from '@components/global/Layout';
import { RouteEnum } from '../model/route.enum';
import LogoIcon from '../../resources/images/logo.svg';
import { Meta } from '@components/global/Meta';

type DataProps = { image1: File; image2: File };

type Props = PageProps<DataProps>;

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "index-1.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image2: file(relativePath: { eq: "index-2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

const Page: React.FC<Props> = (props) => {
  const { data } = props;
  return (
    <Layout isMenuHidden>
      <div className="relative">
        <div className="flex flex-col absolute -z-40">
          <GatsbyImage
            className="h-768 w-512 max-w-screen"
            image={data.image1.childImageSharp.gatsbyImageData}
            alt="Wunderkammer"
          />
        </div>
      </div>
      <LogoIcon className="h-80 w-80 self-end bg-black m-16 sm:m-32" />
      <div className="flex p-16 sm:p-32">
        <div>
          <Link to={RouteEnum.INFO}>
            <h2 className="info-link relative bg-black mr-8">Wunderkammer</h2>
          </Link>
          <div className="h-48 sm:h-72 bg-white border border-white -mt-40 sm:-mt-64 ml-8" />
        </div>
      </div>
      <div className="flex px-16 sm:px-32">
        <div>
          <Link to={RouteEnum.DIGITAL_DIARY}>
            <h3 className="info-link relative bg-black mr-8 text-white p-4 border-white border">
              Digital Diary
            </h3>
          </Link>
          <div className="h-48 bg-white border border-white -mt-40 ml-8" />
        </div>
      </div>
      <div className="flex -mb-80 self-end">
        <div className="mb-80 mt-80 mr-48">
          <Link to={RouteEnum.PROJECTS_12}>
            <h2 className="projects-link relative z-10 bg-black mr-16 mt-32">
              Projekte
            </h2>
          </Link>
          <div className="relative z-0 h-48 sm:h-72 border border-white bg-black -mt-68 sm:-mt-92 ml-16" />
          <div className="relative -z-10 h-48 sm:h-72 border border-white bg-black -mt-68 sm:-mt-92 ml-32 -mr-16" />
          <div className="relative -z-20 h-48 sm:h-72 border border-white bg-black -mt-68 sm:-mt-92 ml-48 -mr-32" />
          <div className="relative -z-30 h-48 sm:h-72 border border-white bg-black -mt-68 sm:-mt-92 ml-64 -mr-48" />
        </div>
      </div>
      <GatsbyImage
        className="-z-30 h-512 w-96 self-end"
        image={data.image2.childImageSharp.gatsbyImageData}
        alt="Wunderkammer"
      />
    </Layout>
  );
};

export const Head = () => {
  return <Meta />;
};

export default Page;
